<bit-container>
  <div class="tw-mt-6 tw-mb-2 tw-pb-2.5">
    <div class="tw-flex tw-items-center tw-gap-2">
      <h1 class="tw-m-0">{{ "devices" | i18n }}</h1>
      <button
        [bitPopoverTriggerFor]="infoPopover"
        type="button"
        class="tw-border-none tw-bg-transparent tw-text-primary-600 tw-flex tw-items-center tw-size-4"
        [position]="'right-start'"
      >
        <i class="bwi bwi-question-circle" aria-hidden="true"></i>
      </button>
      <bit-popover [title]="'whatIsADevice' | i18n" #infoPopover>
        <p>{{ "aDeviceIs" | i18n }}</p>
      </bit-popover>
      <i
        *ngIf="asyncActionLoading"
        class="bwi bwi-spinner bwi-spin tw-flex tw-items-center tw-size-4"
        aria-hidden="true"
      ></i>
    </div>
  </div>

  <p>{{ "deviceListDescriptionTemp" | i18n }}</p>

  <div *ngIf="loading" class="tw-flex tw-justify-center tw-items-center tw-p-4">
    <i class="bwi bwi-spinner bwi-spin tw-text-2xl" aria-hidden="true"></i>
  </div>

  <bit-table-scroll *ngIf="!loading" [dataSource]="dataSource" [rowSize]="50">
    <ng-container header>
      <th
        *ngFor="let col of columnConfig"
        [class]="col.headerClass"
        bitCell
        [bitSortable]="col.sortable ? col.name : null"
        [default]="col.name === 'loginStatus' ? 'desc' : null"
        scope="col"
        role="columnheader"
      >
        {{ col.title }}
      </th>
      <th bitCell scope="col" role="columnheader"></th>
    </ng-container>
    <ng-template bitRowDef let-row>
      <td bitCell class="tw-flex tw-gap-2">
        <div class="tw-flex tw-items-center tw-justify-center tw-w-10">
          <i [class]="getDeviceIcon(row.type)" class="bwi-lg" aria-hidden="true"></i>
        </div>
        <div>
          <ng-container *ngIf="row.hasPendingAuthRequest">
            <a bitLink href="#" appStopClick (click)="managePendingAuthRequest(row)">
              {{ row.displayName }}
            </a>

            <span class="tw-text-sm tw-text-muted tw-block">
              {{ "needsApproval" | i18n }}
            </span>
          </ng-container>
          <ng-container *ngIf="!row.hasPendingAuthRequest">
            {{ row.displayName }}
            <span
              *ngIf="row.trusted && !row.hasPendingAuthRequest"
              class="tw-text-sm tw-text-muted tw-block"
            >
              {{ "trusted" | i18n }}
            </span>
          </ng-container>
        </div>
      </td>
      <td bitCell>
        <span *ngIf="isCurrentDevice(row)" bitBadge variant="primary">{{
          "currentSession" | i18n
        }}</span>
        <span *ngIf="row.hasPendingAuthRequest" bitBadge variant="warning">{{
          "requestPending" | i18n
        }}</span>
      </td>
      <td bitCell>{{ row.firstLogin | date: "medium" }}</td>
      <!-- <td bitCell>
        <button
          type="button"
          bitIconButton="bwi-ellipsis-v"
          [bitMenuTriggerFor]="optionsMenu"
        ></button>
        <bit-menu #optionsMenu>
          Remove device button to be re-added later when we have per device session de-authentication. 
          <button
            type="button"
            bitMenuItem
            (click)="removeDevice(row)"
            [disabled]="isCurrentDevice(row)"
          >
            <span [class]="isCurrentDevice(row) ? 'tw-text-muted' : 'tw-text-danger'">
              <i class="bwi bwi-trash" aria-hidden="true"></i>
              {{ "removeDevice" | i18n }}
            </span>
          </button>
        </bit-menu>
      </td> -->
    </ng-template>
  </bit-table-scroll>
</bit-container>
